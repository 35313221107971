import { useMutation, useQuery } from 'react-query';
import {
	deleteSystemEmailDomain,
	getSystemEmailDomains,
	postSystemEmailDomain,
} from '../../services/endpoints/email-setup';
import {
	Button,
	Divider,
	Input,
	Modal,
	Select,
	Table,
	Tag,
	message,
	notification,
} from 'antd';
import { useState } from 'react';

function SystemAdminDomains() {
	const { data } = useQuery('sys-domains', getSystemEmailDomains);

	return (
		<div>
			<div
				style={{
					display: 'flex',
					alignContent: 'flex-end',
					justifyContent: 'space-between',
					marginBottom: 10,
				}}
			>
				<div>
					<p>System Admin Domains</p>
				</div>
				<SetupEmailDomain />
			</div>
			<Table
				dataSource={data?.data || []}
				columns={[
					{
						title: 'Domain Name',
						dataIndex: 'domainName',
						render: (value, record) => (
							<div>
								<p>{value}</p>
								<p>{record?.isDefault && <Tag>Default</Tag>}</p>
							</div>
						),
					},
					{
						title: 'Sender From Name',
						dataIndex: 'senderFromName',
					},
					{
						title: 'Sender From Email',
						dataIndex: 'senderFromEmail',
					},
					{
						title: 'DNS Verification',
						dataIndex: 'isDnsVerified',
						render: (val) => (val ? 'Verified' : 'Not Verified'),
					},
					{
						title: 'AWS Verification',
						dataIndex: 'isAwsVerified',
						render: (val) => (val ? 'Verified' : 'Not Verified'),
					},
					{
						title: 'Type',
						dataIndex: 'type',
					},
					{
						title: 'Updated',
						dataIndex: 'updatedAt',
					},
					{
						title: '',
						dataIndex: '_id',
						render: (value) => <DeleteDomain id={value} />,
					},
				]}
			/>
			<br />
			<br />
		</div>
	);
}

const DeleteDomain = ({ id }: { id: any }) => {
	const { isLoading, mutate: deleteDomain } = useMutation(
		deleteSystemEmailDomain,
		{
			onSuccess: () => {
				message.success('Domain Deleted Successfully');
			},
		}
	);
	const handleDelete = () => {
		deleteDomain(id);
	};
	return (
		<Button loading={isLoading} danger onClick={handleDelete}>
			Delete
		</Button>
	);
};

export default SystemAdminDomains;

const SetupEmailDomain = () => {
	const { mutate: setupDomain, isLoading } = useMutation(
		postSystemEmailDomain,
		{
			onSuccess: () => {
				setOpen(false);
				notification['success']({
					message: 'Action Successful',
					description: 'Domain setup successfully',
				});
			},
			onError: () => {
				setOpen(false);
				notification['error']({
					message: 'Action Failed',
					description: '',
				});
			},
		}
	);

	const [open, setOpen] = useState(false);

	const [state, setState] = useState({
		domainName: '',
		senderFromName: '',
		senderFromEmail: '',
		replyTo: '',
		domainType: '',
	});

	return (
		<>
			<Button onClick={() => setOpen(true)}>Setup a domain name</Button>

			<Modal
				confirmLoading={isLoading}
				okText="Submit"
				open={open}
				onOk={() => setupDomain(state)}
				onCancel={() => setOpen(false)}
				title="Setup Domain"
			>
				<Divider />
				<div>
					<label htmlFor="domainName">Domain Name</label>
					<Input
						size="large"
						name="domainName"
						id="domainName"
						value={state.domainName}
						onChange={(e) => setState({ ...state, domainName: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="senderFromName">From Name</label>
					<Input
						size="large"
						name="senderFromName"
						id="senderFromName"
						value={state.senderFromName}
						onChange={(e) =>
							setState({ ...state, senderFromName: e.target.value })
						}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="senderFromEmail">From Email</label>
					<Input
						size="large"
						name="senderFromEmail"
						id="senderFromEmail"
						value={state.senderFromEmail}
						onChange={(e) =>
							setState({ ...state, senderFromEmail: e.target.value })
						}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="replyTo">Reply To</label>
					<Input
						size="large"
						name="replyTo"
						id="replyTo"
						value={state.replyTo}
						onChange={(e) => setState({ ...state, replyTo: e.target.value })}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="domainType">Domain Type</label>
					<Select
						style={{ width: '100%' }}
						size="large"
						value={state.domainType}
						onChange={(v) => setState({ ...state, domainType: v })}
					>
						<Select.Option value="single_email">Single Email</Select.Option>
						<Select.Option value="newsletter">Newsletter</Select.Option>
					</Select>
				</div>
			</Modal>
		</>
	);
};
