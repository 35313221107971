import {
	Button,
	Divider,
	Input,
	Modal,
	Select,
	Tag,
	notification,
	Space,
} from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
	useSetDomainStatusMutation,
	useSetupEmailDomainMutation,
	useSetupEmailProfileMutation,
	useViewMerchantsDomainQuery,
} from '../../../services/endpoints/merchant';
import Table from '../../layouts/Table';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';

const SingleMerchantEmailConfiguration = ({ merchant }: { merchant: any }) => {
	const { data: merchantDomains } = useViewMerchantsDomainQuery(
		{ merchantId: merchant?._id },
		{ skip: !merchant?._id }
	);

	const [open, setOpen] = useState(false);
	const [
		submitSetup,
		{ isLoading, isSuccess: profileSetupSuccess, error: profileSetupError },
	] = useSetupEmailProfileMutation();

	const formik = useFormik({
		initialValues: {
			companyName: '',
			email: '',
			password: '',
		},
		onSubmit: (values) => {
			submitSetup({ ...values, merchantId: merchant?._id });
		},
	});

	useEffect(() => {
		if (merchant) {
			formik.setFieldValue('companyName', merchant?.companyName);
			formik.setFieldValue('email', merchant?.companyEmail);
		}
	}, [merchant]);

	useEffect(() => {
		if (profileSetupSuccess) {
			setOpen(false);
			notification['success']({
				message: 'Email profile setup successful!',
			});
		}

		if (profileSetupError) {
			const error: any = profileSetupError;
			notification['error']({
				message: 'Error: Action failed',
				description: error?.data?.error_details?.message,
			});
		}
	}, [profileSetupError, profileSetupSuccess]);

	return (
		<div>
			<div>
				<h2>Email Setup / Configuration</h2>
				<p>Setup email service for this merchant.</p>

				<Space style={{ width: '100%', marginTop: 10 }}>
					{!merchant?.isXendEmailProfileSetup && (
						<Button onClick={() => setOpen(true)}>Setup Email</Button>
					)}
					{!(
						merchant?.isXendEmailDomainSetup &&
						merchant?.isXendEmailNewsletterDomainSetup
					) && <SetupEmailDomain merchantId={merchant?._id} />}
				</Space>
			</div>

			<Modal
				confirmLoading={isLoading}
				title="Setup Email Service"
				open={open}
				onCancel={() => setOpen(false)}
				onOk={() => formik.handleSubmit()}
			>
				<Divider />
				<div>
					<label htmlFor="companyName">Company Name</label>
					<Input
						size="large"
						name="companyName"
						value={formik.values.companyName}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="email">Email</label>
					<Input
						size="large"
						name="email"
						value={formik.values.email}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="password">Password</label>
					<Input
						size="large"
						name="password"
						value={formik.values.password}
						onChange={formik.handleChange}
					/>
				</div>
			</Modal>

			<Table
				dataSource={merchantDomains?.data || []}
				fields={[
					{
						header: 'Domain Name',
						cell: ({ row }) => (
							<div>
								<p>{row.original.domainName}</p>
								{row.original.isDefault && <Tag>Default</Tag>}
							</div>
						),
					},
					{ header: 'Sender Name', accessorFn: (row) => row.senderFromName },
					{ header: 'Type', accessorFn: (row) => row.type },
					{
						header: 'AWS Verified',
						cell: ({ row }) => (row.original.isAwsVerified ? 'True' : 'False'),
					},
					{
						header: 'DNS Verified',
						cell: ({ row }) => (row.original.isDnsVerified ? 'True' : 'False'),
					},
					{
						header: 'Updated',
						accessorFn: (row) => moment(row.updatedAt).format('lll'),
					},
					{
						header: '',
						id: '_id',
						cell: ({ row }) => (
							<UpdateMerchantDomain
								domain={row.original}
								merchantId={merchant?._id}
							/>
						),
					},
				]}
			/>
		</div>
	);
};

export default SingleMerchantEmailConfiguration;

const SetupEmailDomain = ({ merchantId }: { merchantId: any }) => {
	const [setupDomain, { isLoading, isSuccess, isError }] =
		useSetupEmailDomainMutation();

	const [open, setOpen] = useState(false);

	const formik = useFormik({
		initialValues: {
			domainName: '',
			senderFromName: '',
			senderFromEmail: '',
			replyTo: '',
			merchantId: '',
			domainType: '',
		},
		onSubmit: (values) => {
			setupDomain(values);
		},
	});

	useEffect(() => {
		if (merchantId) {
			formik.setFieldValue('merchantId', merchantId);
		}
	}, [merchantId]);

	useEffect(() => {
		if (isSuccess) {
			setOpen(false);
			notification['success']({
				message: 'Action Successful',
				description: 'Domain setup successfully',
			});
		}

		if (isError) {
			setOpen(false);
			notification['error']({
				message: 'Action Failed',
				description: '',
			});
		}
	}, [isSuccess, isError]);

	return (
		<>
			<Button onClick={() => setOpen(true)}>Setup a domain name</Button>

			<Modal
				confirmLoading={isLoading}
				okText="Submit"
				open={open}
				onOk={() => formik.handleSubmit()}
				onCancel={() => setOpen(false)}
				title="Setup Domain"
			>
				<Divider />
				<div>
					<label htmlFor="domainName">Domain Name</label>
					<Input
						size="large"
						name="domainName"
						id="domainName"
						value={formik.values.domainName}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="senderFromName">From Name</label>
					<Input
						size="large"
						name="senderFromName"
						id="senderFromName"
						value={formik.values.senderFromName}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="senderFromEmail">From Email</label>
					<Input
						size="large"
						name="senderFromEmail"
						id="senderFromEmail"
						value={formik.values.senderFromEmail}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="replyTo">Reply To</label>
					<Input
						size="large"
						name="replyTo"
						id="replyTo"
						value={formik.values.replyTo}
						onChange={formik.handleChange}
					/>
				</div>
				<br />
				<div>
					<label htmlFor="domainType">Domain Type</label>
					<Select
						style={{ width: '100%' }}
						size="large"
						value={formik.values.domainType}
						onChange={(v) => formik.setFieldValue('domainType', v)}
					>
						<Select.Option value="single_email">Single Email</Select.Option>
						<Select.Option value="newsletter">Newsletter</Select.Option>
					</Select>
				</div>
			</Modal>
		</>
	);
};

const UpdateMerchantDomain = ({
	domain,
	merchantId,
}: {
	domain: any;
	merchantId: any;
}) => {
	const [open, setOpen] = useState(false);

	const [setDomainStatus, { isLoading, isSuccess, isError }] =
		useSetDomainStatusMutation();

	const setAws = () => {
		setDomainStatus({
			merchantId,
			domainId: domain?._id,
			status: 'aws',
		});
	};

	const setDns = () => {
		setDomainStatus({
			merchantId,
			domainId: domain?._id,
			status: 'dns',
		});
	};

	useEffect(() => {
		if (isSuccess) {
			setOpen(false);
			notification['success']({
				message: 'Action Successful',
				description: 'Updated success.',
			});
		}

		if (isError) {
			notification['error']({
				message: 'Action Failed',
				description: 'Updated Failed.',
			});
		}
	}, [isSuccess, isError]);

	return (
		<>
			<Button onClick={() => setOpen(true)} icon={<EditOutlined />}>
				Edit
			</Button>

			<Modal
				confirmLoading={isLoading}
				width={320}
				open={open}
				onCancel={() => setOpen(false)}
				title="Update Domain"
				footer={null}
			>
				<Divider />
				<div>
					<Button onClick={() => setAws()}>Set AWS</Button>
				</div>
				<Divider />
				<div>
					<Button onClick={() => setDns()}>Set DNS</Button>
				</div>
			</Modal>
		</>
	);
};
